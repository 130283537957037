<template>
  <div class="login">
    <div class="login_wrap">
      <router-link to="/" class="back_home"><<返回首页</router-link>

      <div class="login_box">
        <section class="login_card">
          <div class="login_title">青海省工业小微企业融资服务平台</div>

          <div class="login_from">
            <div class="login_from_left">
              <img src="../../assets/image/11.png" class="login_pic" />
            </div>

            <div class="login_from_rig">
              <div class="login_from_top">
                <!-- <div class="login_font" @click='isUser(1)'>
									<div class="login_from_title" :class='{active: point == 1}'>企业用户</div>
									<span :class='{login_active: point == 1}'></span>
								</div> -->
                <div class="login_font" @click="isUser(2)">
                  <div class="login_from_title" :class="{ active: point == 2 }">
                    金融机构用户
                  </div>
                  <span :class="{ login_active: point == 2 }"></span>
                </div>
              </div>

              <div class="login_user">
                <el-form
                  label-width="43px"
                  :model="formLabelAlign"
                  :rules="rules"
                  ref="ruleForm"
                >
                  <el-form-item prop="name">
                    <div class="login_user_line">
                      <img src="../../assets/image/9.png" class="login_icon" />
                      <el-input
                        v-model.number="formLabelAlign.name"
                        placeholder="请输入手机号码"
                      ></el-input>
                    </div>
                  </el-form-item>

                  <el-form-item prop="region">
                    <div class="login_user_line">
                      <img src="../../assets/image/12.png" class="login_icon" />
                      <el-input
                        v-model="formLabelAlign.region"
                        placeholder="请输入登录密码"
                        type="password"
                      ></el-input>
                    </div>
                  </el-form-item>

                  <el-form-item prop="type">
                    <div class="login_user_line">
                      <img src="../../assets/image/10.png" class="login_icon" />
                      <el-input
                        v-model="formLabelAlign.type"
                        placeholder="请输入图形验证码"
                        class="login_inp"
                      ></el-input>
                      <canvas
                        id="canvas"
                        width="150px"
                        height="50px"
                        ref="canvas"
                        @click="verification"
                      ></canvas>
                    </div>
                  </el-form-item>

                  <div class="login_line">
                    <div class="login_line_no" @click="toForgetpass">
                      忘记密码
                    </div>
                    <div class="login_line_resigter" @click="toResigter">
                      立即注册
                    </div>
                  </div>

                  <div class="login_submit" @click="$throttle(login)">登录</div>
                </el-form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空'));
      }

      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (!this.$regPhone.test(value)) {
            callback(new Error('手机号码格式错误'));
          } else {
            callback();
          }
        }
      }, 500);
    };
    return {
      point: 2,
      formLabelAlign: {
        name: '',
        region: '',
        type: '',
      },
      rules: {
        name: [{ required: true, validator: checkName, trigger: 'blur' }],
        region: [
          { required: true, message: '登录密码不能为空', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '图形验证码不能为空', trigger: 'blur' },
        ],
      },
      code: '',
    };
  },
  methods: {
    toForgetpass() {
      this.$router.push({ path: '/forgetpass', query: { kt: this.point } });
    },
    // 判断用户
    isUser(index) {
      console.log(index);
      this.point = index;
    },
    toResigter() {
      this.$router.push('/resigter');
    },
    // 图形验证码
    verification() {
      let canvas = this.$refs.canvas;
      let cv = canvas.getContext('2d');

      cv.clearRect(0, 0, 150, 50);
      this.formLabelAlign.type = '';
      this.code = '';

      cv.font = '28px arial';
      let words = '0123456789';

      for (let i = 0; i < 4; i++) {
        //随机位置
        let num = Math.floor(Math.random() * words.length);

        let l = Math.floor(Math.random() * (i * 27 + 30 + 1 - i * 27) + i * 25);
        let t = Math.floor(Math.random() * (45 + 1 - 30) + 23);
        //随机颜色
        let r = Math.floor(Math.random() * 256);
        let g = Math.floor(Math.random() * 256);
        let b = Math.floor(Math.random() * 256);
        cv.fillStyle = 'rgb(' + r + ',' + g + ',' + b + ')';
        //随机角度
        let d = Math.floor(Math.random() * (2 + 1 + 2) - 2);
        // cv.rotate(d*Math.PI/180)

        cv.fillText(words[num], l, t, 100);
        this.code += words[num];
        console.log(this.code);
      }
      console.log(2);

      for (let i = 0; i < 10; i++) {
        //随机干扰线
        cv.beginPath();
        let stratx = Math.floor(Math.random() * 201);
        let straty = Math.floor(Math.random() * 51);
        let endx = Math.floor(Math.random() * 201);
        let endy = Math.floor(Math.random() * 51);
        cv.moveTo(stratx, straty);
        cv.lineTo(endx, endy);
        cv.closePath();
        let r = Math.floor(Math.random() * 256);
        let g = Math.floor(Math.random() * 256);
        let b = Math.floor(Math.random() * 256);
        let a = Math.floor(Math.random() * 101);
        cv.strokeStyle = 'rgba(' + r + ',' + g + ',' + b + ',' + a / 100 + ')';
        cv.stroke();
      }
    },
    // 用户信息
    getVali() {
      let data = { token: localStorage.eleToken ? localStorage.eleToken : '' };
      this.$post('/vali', data).then((res) => {
        if (res.data.status == 1) {
          localStorage.setItem('type', res.data.result.type);

          this.$router.replace('/');
        }
      });
    },
    // 登录  type: 1（企业登录） 2（机构登录）
    login() {
      if (this.formLabelAlign.name == '') {
        this.$message({
          message: '手机号码不能为空',
          type: 'error',
        });
        return false;
      }

      if (!this.$regPhone.test(this.formLabelAlign.name)) {
        this.$message({
          message: '手机号码格式错误',
          type: 'error',
        });
        return false;
      }

      if (this.formLabelAlign.region == '') {
        this.$message({
          message: '登录密码不能为空',
          type: 'error',
        });
        return false;
      }

      if (this.formLabelAlign.type == '') {
        this.$message({
          message: '图形验证码不能为空',
          type: 'error',
        });
        return false;
      }

      if (this.formLabelAlign.type.toLowerCase() != this.code.toLowerCase()) {
        this.$message({
          message: '图形验证码错误',
          type: 'error',
        });

        this.verification();

        return false;
      }

      let data = {
        mobile: this.formLabelAlign.name,
        password: this.formLabelAlign.region,
        type: this.point,
      };
      // 设置加载效果
      const loading = this.$loading({
        lock: true,
        text: '正在登录...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.$post('/login', data).then((res) => {
        let type = res.data.status == 1 ? 'success' : 'error';

        this.$message({
          message: res.data.msg,

          type,
          duration: 1000,
        });
        // 关闭加载效果
        loading.close();
        console.log(244, res.data.msg);
        if (res.data.status == 1) {
          localStorage.setItem('eleToken', res.data.result);

          this.getVali();
          this.$router.push('/');
        } else {
          // 登录失败提示
          this.$message.error(res.data.message);
          this.verification();
        }
      });
    },
  },
  mounted() {
    this.verification();
  },
};
</script>

<style scoped>
@import './login.css';
</style>
